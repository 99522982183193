import { set } from './just'

import {
	type InferOutput,
	any,
	array,
	boolean,
	brand,
	check,
	function_,
	integer,
	looseObject,
	maxValue,
	minValue,
	number,
	object,
	optional,
	parse,
	pick,
	picklist,
	pipe,
	safeParse,
	strictObject,
	string,
	transform,
	union,
	unknown,
} from 'valibot'

const valibot = {
	parse,
	safeParse,
	any,
	array,
	boolean,
	check,
	function_,
	number,
	object,
	strictObject,
	looseObject,
	optional,
	picklist,
	pipe,
	string,
	transform,
	union,
	brand,
	pick,
	integer,
	maxValue,
	minValue,
	unknown,
}
type Valibot = typeof valibot
set(window, ['R', 'libs', 'valibot'], valibot)

export {
	parse,
	safeParse,
	any,
	array,
	boolean,
	check,
	function_,
	number,
	object,
	strictObject,
	looseObject,
	optional,
	picklist,
	pipe,
	string,
	transform,
	union,
	brand,
	pick,
	integer,
	maxValue,
	minValue,
	unknown,
}
export type { InferOutput, Valibot }
