import { jsNode } from '@shared/node-v1.0.0'

import v010 from '@nodes/format-number-v0.1.0'

export default jsNode(
	'formatNumber',
	{
		'v0.1.0': v010,
	},
	{ docs: '', color: 'purple' }
)
